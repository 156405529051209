
import { defineComponent, onMounted } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "setup-theme-skeleton",
  components: {
    CodeHighlighter
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Setup theme skeleton");
    });
  }
});
